import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PageLayout from '../components/PageLayout';
// import ContactUs from '../components/ContactUs';
import CandidateOverview from '../components/CandidateOverview';
import Tweets from '../components/Tweets';
import candidates from '../candidates';

// taken from https://stackoverflow.com/questions/4060004/calculate-age-given-the-birth-date-in-the-format-yyyymmdd
function getAge(birthday) {
  const ageDifMs = Date.now() - new Date(birthday).getTime();
  const ageDate = new Date(ageDifMs);

  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(8),
  },
}));

const Candidate = ({ match }) => {
  const classes = useStyles();
  const {id} = match.params;
  const data = candidates[id];

  if (!data) {
    return (
      <div className={classes.container}>
        <Typography variant="h3" align="center" paragraph>
          Oups!
        </Typography>
        <Typography variant="h5" align="center" paragraph>
          The candidate you are looking for was not added to our database, yet ?
        </Typography>
      </div>
    );
  }

  const {name, image, imageAttribution, party, born, state, short, description, website, wikipedia, twitter, quote, withdrawn} = data;
  const age = born ? getAge(born) : undefined;

  return (
    <PageLayout>
      <CandidateOverview name={name} image={image} short={short} description={description} quote={quote} age={age} state={state} party={party} website={website} wikipedia={wikipedia} imageAttribution={imageAttribution} withdrawn={withdrawn} />
      {twitter && <Tweets twitter={twitter} name={name} />}
      {/* <ContactUs /> */}
    </PageLayout>
  );
}

Candidate.propTypes = {
  match: PropTypes.object,
};

export default Candidate;
