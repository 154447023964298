import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Quote from './Quote';
import ExternalLink from './ExternalLink';

const useStyles = makeStyles(theme => ({
  // overview: {
  //   padding: 20,
  // },
  card: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 20,
  },
  summary: {
    paddingTop: 20,
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    width: 400,
    height: 400,
    backgroundPosition: 'top',
    [theme.breakpoints.down('xs')]: {
      margin: '0 auto',
    },
  },
  description: {
    paddingTop: 20,
  },
  buttons: {
    paddingTop: 20,
    paddingLeft: 0,
  },
}));

const CandidateOverview = ({ name, image, imageAttribution, short, description, age, party, state, quote, website, wikipedia, withdrawn }) => {
  const classes = useStyles();
  const descriptionLines = description.split('\n');
  const hasDetailedDescription = descriptionLines.length > 1;
  const imageCredits = imageAttribution ? `Image credits: ${imageAttribution}` : '';

  return (
    <Container>
      <Card className={classes.card} elevation={0}>
        <Tooltip title={imageCredits}>
          <CardMedia
            className={classes.cover}
            image={image}
            // title={name}
          />
        </Tooltip>
        <div className={classes.details}>
          <CardContent className={classes.content}>
            <Typography gutterBottom variant="h3" component="h2">
              {name}
            </Typography>
            <Typography gutterBottom variant="body2" color="textSecondary" component="p">
              {short}
            </Typography>
            <div className={classes.summary}>
              {withdrawn && <Typography gutterBottom variant="body1" component="h2">
                Status: Withdrawn
              </Typography>}
              {party && <Typography gutterBottom variant="body1" component="h2">
                Party: {party}
              </Typography>}
              {age && <Typography gutterBottom variant="body1" component="h2">
                Age: {age} years
              </Typography>}
              {state && <Typography gutterBottom variant="body1" component="h2">
                State: {state}
              </Typography>}
            </div>
            {hasDetailedDescription && <div className={classes.description}>
              {descriptionLines.map(line => (
                <Typography variant="body2" color="textSecondary" component="p" key={line}>
                  {line}
                </Typography>
              ))}
            </div>}
            {quote && <Quote text={quote} />}
            <CardActions className={classes.buttons}>
              {website && <ExternalLink link={website}><Button variant="contained" color="primary" size="small">Website</Button></ExternalLink>}
              {wikipedia && <ExternalLink link={wikipedia}><Button variant="contained" color="primary" size="small">Wikipedia</Button></ExternalLink>}
            </CardActions>
          </CardContent>
        </div>
      </Card>
    </Container>
  );
}

CandidateOverview.defaultProps = {
  withdrawn: false,
};

CandidateOverview.propTypes = {
  name: PropTypes.string,
  image: PropTypes.string,
  imageAttribution: PropTypes.string,
  short: PropTypes.string,
  description: PropTypes.string,
  age: PropTypes.number,
  party: PropTypes.string,
  state: PropTypes.string,
  quote: PropTypes.string,
  website: PropTypes.string,
  wikipedia: PropTypes.string,
  withdrawn: PropTypes.bool,
};

export default CandidateOverview;
