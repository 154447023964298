import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import CardActionArea from '@material-ui/core/CardActionArea';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
  card: {
    maxWidth: 345,
  },
  media: {
    height: 400,
    backgroundPosition: 'top',
  },
});

const CandidateCard = ({ name, image, imageAttribution, description, party, link }) => {
  const classes = useStyles();
  const imageCredits = imageAttribution ? `Image credits: ${imageAttribution}` : '';

  return (
    <Card className={classes.card}>
      <CardActionArea component={Link} to={link}>
        <Tooltip title={imageCredits} placement="top">
          <CardMedia
            className={classes.media}
            image={image}
            // title={name}
          />
        </Tooltip>
        <CardContent>
          <Typography variant="h5" component="h2">
            {name}
          </Typography>
          <Typography gutterBottom variant="overline" color="textSecondary" component="p">
            {party}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

CandidateCard.defaultProps = {
  linkTitle: 'Learn more',
};

CandidateCard.propTypes = {
  name: PropTypes.string,
  image: PropTypes.string,
  imageAttribution: PropTypes.string,
  description: PropTypes.string,
  party: PropTypes.string,
  link: PropTypes.string,
};

export default CandidateCard;
