export default {
  "donald-trump": {
    "name": "Donald Trump",
    "description": "President of the United States (2017–present)",
    "image": "/images/donald-trump.jpg",
    "party": "Republican party",
    "wikipedia": "https://en.wikipedia.org/wiki/Donald_Trump",
    "website": "https://www.donaldjtrump.com/",
    "birthday": "1946-06-14",
    "state": "New York",
    "born": "1946-06-13T23:00:00.000Z",
    "short": "President of the United States (2017–present)",
    "twitter": "https://twitter.com/realDonaldTrump",
    "originalImage": "https://upload.wikimedia.org/wikipedia/commons/5/53/Donald_Trump_official_portrait_%28cropped%29.jpg"
  },
  "bill-weld": {
    "name": "Bill Weld",
    "description": "Governor of Massachusetts (1991–1997)\nLibertarian nominee for Vice President in 2016\nRepublican nominee for U.S. Senate from Massachusetts in 1996",
    "image": "/images/bill-weld.jpg",
    "party": "Republican party",
    "wikipedia": "https://en.wikipedia.org/wiki/Bill_Weld",
    "website": "https://www.weld2020.org/",
    "birthday": "1945-07-31",
    "state": "Massachusetts",
    "born": "1945-07-30T23:00:00.000Z",
    "short": "Governor of Massachusetts (1991–1997)",
    "twitter": "https://twitter.com/GovBillWeld",
    "originalImage": "https://upload.wikimedia.org/wikipedia/commons/0/0c/Bill_Weld_campaign_portrait.jpg"
  },
  "michael-bennet": {
    "name": "Michael Bennet",
    "description": "U.S. Senator from Colorado (2009–present)",
    "image": "/images/michael-bennet.jpg",
    "party": "Democratic party",
    "wikipedia": "https://en.wikipedia.org/wiki/Michael_Bennet",
    "website": "https://bennet.senate.gov/",
    "state": "Colorado",
    "born": "1964-11-28T00:00:00.000Z",
    "short": "U.S. Senator from Colorado (2009–present)",
    "twitter": "https://twitter.com/senatorbennet",
    "originalImage": "https://upload.wikimedia.org/wikipedia/commons/f/fc/Michael_Bennet_Official_Photo.jpg"
  },
  "joe-biden": {
    "name": "Joe Biden",
    "description": "Vice President of the United States (2009–2017)\nU.S. Senator from Delaware (1973–2009)\nDemocratic candidate for President in 1988 and 2008",
    "image": "/images/joe-biden.jpg",
    "party": "Democratic party",
    "wikipedia": "https://en.wikipedia.org/wiki/Joe_Biden",
    "website": "https://joebiden.com/",
    "state": "Delaware",
    "born": "1942-11-19T23:00:00.000Z",
    "short": "Vice President of the United States (2009–2017)",
    "originalImage": "https://upload.wikimedia.org/wikipedia/commons/6/64/Biden_2013.jpg",
    "twitter": "https://twitter.com/joebiden"
  },
  "cory-booker": {
    "name": "Cory Booker",
    "description": "U.S. Senator from New Jersey (2013–present)\nMayor of Newark, New Jersey (2006–2013)",
    "image": "/images/cory-booker.jpg",
    "party": "Democratic party",
    "wikipedia": "https://en.wikipedia.org/wiki/Cory_Booker",
    "website": "https://www.booker.senate.gov/",
    "state": "New Jersey",
    "born": "1969-04-26T23:00:00.000Z",
    "short": "U.S. Senator from New Jersey (2013–present)",
    "originalImage": "https://upload.wikimedia.org/wikipedia/commons/5/59/Cory_Booker%2C_official_portrait%2C_114th_Congress.jpg",
    "twitter": "https://twitter.com/senbooker"
  },
  "steve-bullock": {
    "name": "Steve Bullock",
    "description": "Governor of Montana (2013–present)\nAttorney General of Montana (2009–2013)",
    "image": "/images/steve-bullock.jpg",
    "imageAttribution": "Gage Skidmore",
    "party": "Democratic party",
    "wikipedia": "https://en.wikipedia.org/wiki/Steve_Bullock_(American_politician)",
    "website": "https://secure.actblue.com/donate/bfp2020_homepage",
    "state": "Montana",
    "born": "1966-04-10T23:00:00.000Z",
    "short": "Governor of Montana (2013–present)",
    "originalImage": "https://upload.wikimedia.org/wikipedia/commons/f/f1/Steve_Bullock_by_Gage_Skidmore.jpg",
    "twitter": "https://twitter.com/GovernorBullock"
  },
  "pete-buttigieg": {
    "name": "Pete Buttigieg",
    "description": "Mayor of South Bend, Indiana (2012–present)\nCandidate for DNC Chair in 2017\nDemocratic nominee for Treasurer of Indiana in 2010",
    "image": "/images/pete-buttigieg.jpg",
    "imageAttribution": "Gage Skidmore",
    "party": "Democratic party",
    "wikipedia": "https://en.wikipedia.org/wiki/Pete_Buttigieg",
    "website": "https://peteforamerica.com/",
    "state": "Indiana",
    "born": "1982-01-19T00:00:00.000Z",
    "short": "Mayor of South Bend, Indiana (2012–present)",
    "originalImage": "https://upload.wikimedia.org/wikipedia/commons/b/bf/Pete_Buttigieg_by_Gage_Skidmore.jpg",
    "twitter": "https://twitter.com/PeteButtigieg"
  },
  "julian-castro": {
    "name": "Julian Castro",
    "description": "U.S. Secretary of Housing and Urban Development (2014–2017)\nMayor of San Antonio, Texas (2009–2014)",
    "image": "/images/julian-castro.jpg",
    "party": "Democratic party",
    "wikipedia": "https://en.wikipedia.org/wiki/Julian_Castro",
    "website": "https://www.julianforthefuture.com/",
    "state": "Texas",
    "born": "1974-09-15T23:00:00.000Z",
    "short": "U.S. Secretary of Housing and Urban Development (2014–2017)",
    "originalImage": "https://upload.wikimedia.org/wikipedia/commons/c/c6/Juli%C3%A1n_Castro%27s_Official_HUD_Portrait.jpg",
    "twitter": "https://twitter.com/JulianCastro"
  },
  "bill-de-blasio": {
    "name": "Bill de Blasio",
    "description": "Mayor of New York City, New York (2014–present)",
    "image": "/images/bill-de-blasio.jpg",
    "party": "Democratic party",
    "wikipedia": "https://en.wikipedia.org/wiki/Bill_de_Blasio",
    "website": "https://billdeblasio.com/",
    "state": "New York",
    "born": "1961-05-07T23:00:00.000Z",
    "short": "Mayor of New York City, New York (2014–present)",
    "originalImage": "https://upload.wikimedia.org/wikipedia/commons/2/2e/Bill_de_Blasio_January_2019.jpg",
    "twitter": "https://twitter.com/nycmayor"
  },
  "john-delaney": {
    "name": "John Delaney",
    "description": "U.S. Representative from MD-06 (2013–2019)",
    "image": "/images/john-delaney.jpg",
    "party": "Democratic party",
    "wikipedia": "https://en.wikipedia.org/wiki/John_Delaney_(Maryland_politician)",
    "website": "https://www.johndelaney.com/",
    "state": "Maryland",
    "born": "1963-04-15T23:00:00.000Z",
    "short": "U.S. Representative from MD-06 (2013–2019)",
    "originalImage": "https://upload.wikimedia.org/wikipedia/commons/1/1d/John_Delaney_113th_Congress_official_photo.jpg",
    "twitter": "https://twitter.com/johndelaney"
  },
  "tulsi-gabbard": {
    "name": "Tulsi Gabbard",
    "description": "U.S. Representative from HI-02 (2013–present)\nVice Chair of the DNC (2013-2016)\nMember of the Honolulu City Council (2011-2012)",
    "image": "/images/tulsi-gabbard.jpg",
    "party": "Democratic party",
    "wikipedia": "https://en.wikipedia.org/wiki/Tulsi_Gabbard",
    "website": "https://www.tulsi2020.com/",
    "state": "Hawaii",
    "born": "1981-04-11T23:00:00.000Z",
    "short": "U.S. Representative from HI-02 (2013–present)",
    "originalImage": "https://upload.wikimedia.org/wikipedia/commons/2/2a/Tulsi_Gabbard%2C_official_portrait%2C_113th_Congress.jpg",
    "twitter": "https://twitter.com/TulsiGabbard"
  },
  "kirsten-gillibrand": {
    "name": "Kirsten Gillibrand",
    "description": "U.S. Senator from New York (2009–present)\nU.S. Representative from NY-20 (2007–2009)",
    "image": "/images/kirsten-gillibrand.jpg",
    "party": "Democratic party",
    "wikipedia": "https://en.wikipedia.org/wiki/Kirsten_Gillibrand",
    "website": "https://kirstengillibrand.com/",
    "state": "New York",
    "born": "1966-12-09T00:00:00.000Z",
    "short": "U.S. Senator from New York (2009–present)",
    "originalImage": "https://upload.wikimedia.org/wikipedia/commons/9/9a/Kirsten_Gillibrand%2C_official_photo%2C_116th_Congress.jpg",
    "twitter": "https://twitter.com/SenGillibrand"
  },
  "mike-gravel": {
    "name": "Mike Gravel",
    "description": "U.S. Senator from Alaska (1969–1981)\nSpeaker of the Alaska House of Representatives (1965-1967)\nDemocratic and Libertarian candidate for President in 2008\nCandidate for Vice President in 1972",
    "image": "/images/mike-gravel.png",
    "imageAttribution": "David Oks",
    "party": "Democratic party",
    "wikipedia": "https://en.wikipedia.org/wiki/Mike_Gravel",
    "website": "https://www.mikegravel.org/",
    "state": "California",
    "born": "1930-05-12T23:00:00.000Z",
    "short": "U.S. Senator from Alaska (1969–1981)",
    "originalImage": "https://upload.wikimedia.org/wikipedia/commons/1/1d/Mike_Gravel.png",
    "twitter": "https://twitter.com/MikeGravel",
    "withdrawn": true
  },
  "kamala-harris": {
    "name": "Kamala Harris",
    "description": "U.S. Senator from California (2017–present)\nAttorney General of California (2011–2017)\nDistrict Attorney of San Francisco (2004-2011)",
    "image": "/images/kamala-harris.jpg",
    "party": "Democratic party",
    "wikipedia": "https://en.wikipedia.org/wiki/Kamala_Harris",
    "website": "https://kamalaharris.org/",
    "state": "California",
    "born": "1964-10-19T23:00:00.000Z",
    "short": "U.S. Senator from California (2017–present)",
    "originalImage": "https://upload.wikimedia.org/wikipedia/commons/d/dd/Senator_Harris_official_senate_portrait.jpg",
    "twitter": "https://twitter.com/KamalaHarris"
  },
  "john-hickenlooper": {
    "name": "John Hickenlooper",
    "description": "Governor of Colorado (2011–2019)Mayor of Denver, Colorado (2003–2011)",
    "image": "/images/john-hickenlooper.jpg",
    "imageAttribution": "Gage Skidmore",
    "party": "Democratic party",
    "wikipedia": "https://en.wikipedia.org/wiki/John_Hickenlooper",
    "website": "https://www.hickenlooper.com/",
    "state": "Colorado",
    "born": "1952-02-07T00:00:00.000Z",
    "short": "Governor of Colorado (2011–2019)Mayor of Denver, Colorado (2003–2011)",
    "originalImage": "https://upload.wikimedia.org/wikipedia/commons/f/f5/John_Hickenlooper_by_Gage_Skidmore.jpg",
    "twitter": "https://twitter.com/Hickenlooper"
  },
  "jay-inslee": {
    "name": "Jay Inslee",
    "description": "Governor of Washington (2013–present)\nU.S. Representative from WA-01 (1999–2012)\nU.S. Representative from WA-04 (1993–1995)",
    "image": "/images/jay-inslee.jpg",
    "party": "Democratic party",
    "wikipedia": "https://en.wikipedia.org/wiki/Jay_Inslee",
    "website": "https://jayinslee.com/",
    "state": "Washington",
    "born": "1951-02-09T00:00:00.000Z",
    "short": "Governor of Washington (2013–present)",
    "originalImage": "https://upload.wikimedia.org/wikipedia/commons/2/24/Jay_Inslee_official_portrait.jpg",
    "twitter": "https://twitter.com/jayinslee"
  },
  "amy-klobuchar": {
    "name": "Amy Klobuchar",
    "description": "U.S. Senator from Minnesota (2007–present)",
    "image": "/images/amy-klobuchar.jpg",
    "party": "Democratic party",
    "wikipedia": "https://en.wikipedia.org/wiki/Amy_Klobuchar",
    "website": "https://amyklobuchar.com/",
    "state": "Minnesota",
    "born": "1960-05-24T23:00:00.000Z",
    "short": "U.S. Senator from Minnesota (2007–present)",
    "originalImage": "https://upload.wikimedia.org/wikipedia/commons/b/b7/Amy_Klobuchar%2C_official_portrait%2C_113th_Congress.jpg",
    "twitter": "https://twitter.com/amyklobuchar"
  },
  "wayne-messam": {
    "name": "Wayne Messam",
    "description": "Mayor of Miramar, Florida (2015–present)",
    "image": "/images/wayne-messam.jpg",
    "party": "Democratic party",
    "wikipedia": "https://en.wikipedia.org/wiki/Wayne_Messam",
    "website": "https://wayneforusa.com/",
    "state": "Florida",
    "born": "1974-06-06T23:00:00.000Z",
    "short": "Mayor of Miramar, Florida (2015–present)",
    "originalImage": "https://upload.wikimedia.org/wikipedia/commons/a/a5/Mayor_Messam.jpg",
    "twitter": "https://twitter.com/WayneMessam"
  },
  "seth-moulton": {
    "name": "Seth Moulton",
    "description": "U.S. Representative from MA-06 (2015–present)",
    "image": "/images/seth-moulton.jpg",
    "party": "Democratic party",
    "wikipedia": "https://en.wikipedia.org/wiki/Seth_Moulton",
    "website": "https://secure.actblue.com/donate/seth-moulton-2",
    "state": "Massachusetts",
    "born": "1978-10-23T23:00:00.000Z",
    "short": "U.S. Representative from MA-06 (2015–present)",
    "originalImage": "https://upload.wikimedia.org/wikipedia/commons/4/4e/Seth_Moulton.jpg",
    "twitter": "https://twitter.com/sethmoulton"
  },
  "beto-orourke": {
    "name": "Beto O'Rourke",
    "description": "U.S. Representative from TX-16 (2013–2019)\nDemocratic nominee for U.S. Senate from Texas in 2018",
    "image": "/images/beto-orourke.jpg",
    "party": "Democratic party",
    "wikipedia": "https://en.wikipedia.org/wiki/Beto_O%27Rourke",
    "website": "https://betoorourke.com/",
    "state": "Texas",
    "born": "1972-09-25T23:00:00.000Z",
    "short": "U.S. Representative from TX-16 (2013–2019)",
    "originalImage": "https://upload.wikimedia.org/wikipedia/commons/a/ad/Beto_O%27Rourke%2C_Official_portrait%2C_113th_Congress.jpg",
    "twitter": "https://twitter.com/BetoORourke"
  },
  "tim-ryan": {
    "name": "Tim Ryan",
    "description": "U.S. Representative from OH-13 (2013–present)\nU.S. Representative from OH-17 (2003–2013)",
    "image": "/images/tim-ryan.jpg",
    "party": "Democratic party",
    "wikipedia": "https://en.wikipedia.org/wiki/Tim_Ryan_(Ohio_politician)",
    "website": "https://timryanforamerica.com/",
    "state": "Ohio",
    "born": "1973-07-15T23:00:00.000Z",
    "short": "U.S. Representative from OH-13 (2013–present)",
    "originalImage": "https://upload.wikimedia.org/wikipedia/commons/6/6d/Rep._Tim_Ryan_Congressional_Head_Shot_2010.jpg",
    "twitter": "https://twitter.com/timryan"
  },
  "bernie-sanders": {
    "name": "Bernie Sanders",
    "description": "U.S. Senator from Vermont (2007–present)\nU.S. Representative from VT-AL (1991–2007)\nMayor of Burlington, Vermont (1981–1989)\nDemocratic candidate for President in 2016\nCandidate for Governor of Vermont in 1972, 1976, and 1986",
    "image": "/images/bernie-sanders.jpg",
    "party": "Democratic party",
    "wikipedia": "https://en.wikipedia.org/wiki/Bernie_Sanders",
    "website": "https://berniesanders.com/",
    "state": "Vermont",
    "born": "1941-09-07T23:00:00.000Z",
    "short": "U.S. Senator from Vermont (2007–present)",
    "originalImage": "https://upload.wikimedia.org/wikipedia/commons/d/de/Bernie_Sanders.jpg",
    "twitter": "https://twitter.com/BernieSanders"
  },
  "joe-sestak": {
    "name": "Joe Sestak",
    "description": "U.S. Representative from PA-07 (2007–2011)\nDemocratic candidate for U.S. Senate from Pennsylvania in 2010 and 2016",
    "image": "/images/joe-sestak.jpg",
    "party": "Democratic party",
    "wikipedia": "https://en.wikipedia.org/wiki/Joe_Sestak",
    "website": "https://www.joesestak.com/",
    "state": "Pennsylvania",
    "born": "1951-12-12T00:00:00.000Z",
    "short": "U.S. Representative from PA-07 (2007–2011)",
    "originalImage": "https://upload.wikimedia.org/wikipedia/commons/f/f4/Congressman_Sestak_Official_Congressional_headshot.jpg",
    "twitter": "https://twitter.com/JoeSestak"
  },
  "elizabeth-warren": {
    "name": "Elizabeth Warren",
    "description": "U.S. Senator from Massachusetts (2013–present)\nSpecial Advisor for the Consumer Financial Protection Bureau (2010-2011)",
    "image": "/images/elizabeth-warren.jpg",
    "party": "Democratic party",
    "wikipedia": "https://en.wikipedia.org/wiki/Elizabeth_Warren",
    "website": "https://elizabethwarren.com/",
    "state": "Massachusetts",
    "born": "1949-06-21T23:00:00.000Z",
    "short": "U.S. Senator from Massachusetts (2013–present)",
    "originalImage": "https://upload.wikimedia.org/wikipedia/commons/6/6a/Elizabeth_Warren%2C_official_portrait%2C_114th_Congress.jpg",
    "twitter": "https://twitter.com/ewarren"
  },
  "marianne-williamson": {
    "name": "Marianne Williamson",
    "description": "Author, lecturer, and activist\nIndependent candidate for U.S. Representative from CA-33 in 2014",
    "image": "/images/marianne-williamson.jpg",
    "imageAttribution": "marcn",
    "party": "Democratic party",
    "wikipedia": "https://en.wikipedia.org/wiki/Marianne_Williamson",
    "website": "https://www.marianne2020.com/",
    "state": "Iowa",
    "born": "1952-07-07T23:00:00.000Z",
    "short": "Author, lecturer, and activist",
    "originalImage": "https://upload.wikimedia.org/wikipedia/commons/6/61/Marianne_Williamson_-_33252886458_%28cropped%29.jpg",
    "twitter": "https://twitter.com/marwilliamson"
  },
  "andrew-yang": {
    "name": "Andrew Yang",
    "description": "Attorney, entrepreneur, philanthropist, and founder of Venture for America",
    "image": "/images/andrew-yang.jpg",
    "imageAttribution": "Asa Mathat for Techonomy",
    "party": "Democratic party",
    "wikipedia": "https://en.wikipedia.org/wiki/Andrew_Yang",
    "state": "New York",
    "born": "1975-01-13T00:00:00.000Z",
    "short": "Attorney, entrepreneur, philanthropist, and founder of Venture for America",
    "originalImage": "https://upload.wikimedia.org/wikipedia/commons/2/26/Andrew_Yang_talking_about_urban_entrepreneurship_at_Techonomy_Conference_2015_in_Detroit%2C_MI_%28cropped%29.jpg",
    "twitter": "https://twitter.com/AndrewYang",
    "website": "https://www.yang2020.com/"
  },
  "max-abramson": {
    "name": "Max Abramson",
    "description": "New Hampshire State Representative 2014-2017, 2019-present\nNominee for Governor of New Hampshire in 2016",
    "image": "/images/max-abramson.jpg",
    "imageAttribution": "Maxxoccupancy",
    "party": "Libertarian Party",
    "wikipedia": "https://en.wikipedia.org/wiki/Max_Abramson",
    "state": "New Hampshire",
    "born": "1976-04-28T23:00:00.000Z",
    "short": "New Hampshire State Representative 2014-2017, 2019-present",
    "originalImage": "https://upload.wikimedia.org/wikipedia/commons/b/b8/Max_suit_small.jpg",
    "twitter": "https://twitter.com/maxxoccupancy",
    "website": "http://www.maxabramson.org/"
  },
  "adam-kokesh": {
    "name": "Adam Kokesh",
    "description": "Libertarian and anti-war political activist\nCandidate for U.S. Senate from Arizona in 2018\nRepublican candidate for U.S. Representative from NM-03 in 2010",
    "image": "/images/adam-kokesh.jpg",
    "imageAttribution": "Gage Skidmore",
    "party": "Libertarian Party",
    "wikipedia": "https://en.wikipedia.org/wiki/Adam_Kokesh",
    "state": "Arizona",
    "born": "1982-02-01T00:00:00.000Z",
    "short": "Libertarian and anti-war political activist",
    "originalImage": "https://upload.wikimedia.org/wikipedia/commons/e/e7/Kokesh2013.jpg",
    "twitter": "https://twitter.com/adamkokesh",
    "website": "https://kokeshforpresident.com/"
  },
  "john-mcafee": {
    "name": "John McAfee",
    "description": "Founder and CEO of McAfee, Inc\n1987–1994 Candidate for President in 2016",
    "image": "/images/john-mcafee.jpg",
    "imageAttribution": "Gage Skidmore",
    "party": "Libertarian Party",
    "wikipedia": "https://en.wikipedia.org/wiki/John_McAfee",
    "state": "Tennessee",
    "born": "1945-09-17T23:00:00.000Z",
    "short": "Founder and CEO of McAfee, Inc",
    "originalImage": "https://upload.wikimedia.org/wikipedia/commons/7/7a/John_McAfee_by_Gage_Skidmore.jpg",
    "twitter": "https://twitter.com/officialmcafee",
    "website": "http://mcafee2020hq.com/"
  },
  "vermin-supreme": {
    "name": "Vermin Supreme",
    "description": "Performance artist and activist\nCandidate for President in 1992, 1996, 2000, 2004, 2008, 2012, and 2016\nCandidate for Mayor of Detroit, Michigan in 1989\nCandidate for Mayor of Baltimore, Maryland in 1987",
    "image": "/images/vermin-supreme.jpg",
    "imageAttribution": "Alex Lozupone",
    "party": "Libertarian Party",
    "wikipedia": "https://en.wikipedia.org/wiki/Vermin_Supreme",
    "website": "https://verminsupreme2016.wordpress.com/about/",
    "state": "Kansas",
    "born": "1961-05-31T23:00:00.000Z",
    "short": "Performance artist and activist",
    "originalImage": "https://upload.wikimedia.org/wikipedia/commons/f/ff/Lozwp_DSC00677.jpg",
    "twitter": "https://twitter.com/VerminSupreme"
  },
  "arvin-vohra": {
    "name": "Arvin Vohra",
    "description": "Vice Chair of the LNC 2014–2018Nominee for U.S. Senate from Maryland in 2018\nNominee for U.S. Representative from Maryland in 2012 and 2014\nNominee for U.S. Senate from Maryland in 2016",
    "image": "/images/arvin-vohra.jpg",
    "imageAttribution": "Liberty.me, Tatiana Moroz",
    "party": "Libertarian Party",
    "wikipedia": "https://en.wikipedia.org/wiki/Arvin_Vohra",
    "state": "Maryland",
    "born": "1979-05-08T23:00:00.000Z",
    "short": "Vice Chair of the LNC 2014–2018Nominee for U.S. Senate from Maryland in 2018",
    "originalImage": "https://upload.wikimedia.org/wikipedia/commons/c/cd/Arvin_Vohra_on_The_Tatiana_Show.jpg",
    "twitter": "https://twitter.com/votevohra",
    "website": "http://www.votevohra.com/"
  },
  "sedinam-kinamo-christin-moyowasifza-curry": {
    "name": "Sedinam Kinamo Christin Moyowasifza-Curry",
    "description": "People's National Convention organizer\nGreen candidate for President in 2016",
    "image": "/images/sedinam-kinamo-christin-moyowasifza-curry.jpg",
    "imageAttribution": "Sedinam Kinamo Christin Moyowasifza-Curry",
    "party": "Green Party",
    "wikipedia": "https://en.wikipedia.org/wiki/Sedinam_Kinamo_Christin_Moyowasifza_Curry",
    "state": "California",
    "born": "1962-01-01T00:00:00.000Z",
    "short": "People's National Convention organizer",
    "originalImage": "https://scontent-lht6-1.xx.fbcdn.net/v/t1.0-9/64607009_2327927987420172_3780109122184151040_n.jpg?_nc_cat=109&_nc_oc=AQmO8bxi3lRpNfgnTmToqkN4f1Dv6psnda7Mx6Vpv7oLXodKB9W9HX5Rm3J7vUyFeW0&_nc_ht=scontent-lht6-1.xx&oh=684986712bed6901f4907b7322ffb9a8&oe=5DBF399E",
    "twitter": "https://twitter.com/skcmcurry",
    "website": "http://sedinam2020.com/"
  },
  "howie-hawkins": {
    "name": "Howie Hawkins",
    "description": "Co-Founder of the Green Party\nGreen nominee for Governor of New York in 2010, 2014, and 2018\nGreen nominee for U.S. Representative from NY-25 in 2008\nGreen nominee for U.S. Senate from New York in 2006",
    "image": "/images/howie-hawkins.jpg",
    "party": "Green Party",
    "wikipedia": "https://en.wikipedia.org/wiki/Howie_Hawkins",
    "state": "New York",
    "born": "1952-12-08T00:00:00.000Z",
    "short": "Co-Founder of the Green Party",
    "originalImage": "https://upload.wikimedia.org/wikipedia/commons/d/df/Hawkins_2010.jpg",
    "twitter": "https://twitter.com/howiehawkins20",
    "website": "https://howiehawkins.us/"
  },
  "roland-aranjo": {
    "name": "Roland Aranjo",
    "description": "Public Servant and Self-help Author",
    "image": "/images/roland-aranjo.jpg",
    "imageAttribution": "www.rolandforpresident.org, Roland Aranjo",
    "party": "Green Party",
    "wikipedia": "",
    "state": "Arizona",
    "born": "1961-09-15T00:00:00.000Z",
    "short": "Public Servant and Self-help Author",
    "originalImage": "https://static.wixstatic.com/media/ccf52b_7f128a97fd094285bc2c34a69bb0fdf2~mv2.jpg",
    "twitter": "https://twitter.com/rolandaranjo",
    "website": "https://www.rolandforpresident.org"
  },
  "dario-hunter": {
    "name": "Dario Hunter",
    "description": "Youngstown Board of Education member (2016–present)",
    "image": "/images/dario-hunter.jpg",
    "imageAttribution": "Dario Hunter",
    "party": "Green Party",
    "wikipedia": "https://en.wikipedia.org/wiki/Dario_Hunter",
    "state": "Ohio",
    "born": "1983-04-20T23:00:00.000Z",
    "short": "Youngstown Board of Education member (2016–present)",
    "originalImage": "https://upload.wikimedia.org/wikipedia/commons/d/db/Dario_Hunter_YCSD_%28cropped%29.jpg",
    "twitter": "https://twitter.com/dario4america",
    "website": "http://darioshow.com/"
  },
  "dennis-lambert": {
    "name": "Dennis Lambert",
    "description": "US Army Veteran\nGreen nominee for U.S. representative from OH-06 in 2014",
    "image": "/images/dennis-lambert.jpg",
    "imageAttribution": "www.dlpotus2020.com, Dennis Lambert",
    "party": "Green Party",
    "wikipedia": "",
    "state": "Ohio",
    "born": "1974-03-01T23:00:00.000Z",
    "short": "US Army Veteran",
    "originalImage": "https://static.wixstatic.com/media/13a151_8760df8dc9ea45759e2161c21cbd8820~mv2.jpg",
    "twitter": "https://twitter.com/dlpotus2020",
    "website": "https://www.dlpotus2020.com"
  },
  "ian-schlakman": {
    "name": "Ian Schlakman",
    "description": "Former co-chair of the Maryland Green PartyGreen nominee for Governor of Maryland in 2018\nGreen nominee for U.S. Representative from MD-02 in 2014",
    "image": "/images/ian-schlakman.jpg",
    "imageAttribution": "Ian Schlakman for President 2020, People for Ian Schlakman",
    "party": "Green Party",
    "wikipedia": "",
    "state": "Maryland",
    "born": "1984-12-15T00:00:00.000Z",
    "short": "Former co-chair of the Maryland Green PartyGreen nominee for Governor of Maryland in 2018",
    "originalImage": "https://upload.wikimedia.org/wikipedia/commons/f/fe/Ian_Schlakman.jpg",
    "twitter": "https://twitter.com/ischlakman",
    "website": "https://www.schlakman.com"
  },
  "brian-t-carroll": {
    "name": "Brian T\nCarroll",
    "description": "Teacher\nIndependent candidate for U.S. Representative from CA-22 in 2018",
    "image": "/images/brian-t-carroll.jpg",
    "imageAttribution": "Roy Dressel",
    "party": "American Solidarity Party",
    "wikipedia": "https://en.wikipedia.org/wiki/Brian_T._Carroll",
    "state": "California",
    "born": "1949-01-01T00:00:00.000Z",
    "short": "Teacher",
    "originalImage": "https://upload.wikimedia.org/wikipedia/commons/b/bf/Brian_T._Carroll.jpg",
    "twitter": "https://twitter.com/briantcarroll",
    "website": "http://blog.briantcarroll.com/"
  },
  "joe-schriner": {
    "name": "Joe Schriner",
    "description": "Political activist and journalist\nRepublican and Independent candidate for President in 2016\nIndependent candidate for President in 2012\nGreen and Independent candidate for President in 2008\nIndependent candidate for President in 2004\nRepublican and Independent candidate for President in 2000",
    "image": "/images/joe-schriner.jpg",
    "imageAttribution": "Joe Schriner",
    "party": "American Solidarity Party",
    "wikipedia": "https://en.wikipedia.org/wiki/Joe_Schriner",
    "state": "Ohio",
    "born": "1955-03-03T00:00:00.000Z",
    "short": "Political activist and journalist",
    "originalImage": "https://scontent-lht6-1.xx.fbcdn.net/v/t1.0-9/51769740_2364530210449401_1249478399555010560_n.jpg?_nc_cat=108&_nc_oc=AQmaS_kzvhsXxBR8vT2e_nnCKo1flktEd6yF96yph9vpjAA78aUQ03pxDUyE90tlhfg&_nc_ht=scontent-lht6-1.xx&oh=953f8b3d0db6fda8d15dde951cecd42d&oe=5DA45C4B",
    "twitter": "https://twitter.com/joe_schriner",
    "website": "https://www.voteforjoe.com/"
  },
  "joshua-perkins": {
    "name": "Joshua Perkins",
    "description": "Actuarial programmer at a life insurance company",
    "image": "/images/joshua-perkins.jpg",
    "imageAttribution": "Joshua Perkins",
    "party": "American Solidarity Party",
    "wikipedia": "",
    "state": "Texas",
    "born": "1984-01-01T00:00:00.000Z",
    "short": "Actuarial programmer at a life insurance company",
    "originalImage": "https://solidarity-party.org/wp-content/uploads/2019/03/12289466_10100315246435441_1768399967074328807_n.jpg",
    "website": "https://solidarity-party.org/2019/03/14/presidential-candidate-spotlight-joshua-perkins/",
    "twitter": ""
  },
  "clconnie-gammon": {
    "name": "C.L.\"Connie\" Gammon",
    "description": "Writer and historian",
    "image": "/images/clconnie-gammon.jpg",
    "imageAttribution": "C.L.\"Connie\" Gammon",
    "party": "Prohibition Party",
    "state": "Tennessee",
    "wikipedia": "",
    "website": "https://gammonforveep.home.blog/",
    "born": "1958-02-05T00:00:00.000Z",
    "short": "Writer and historian",
    "originalImage": "https://scontent-lht6-1.xx.fbcdn.net/v/t1.0-9/57536320_2342070926071110_6748855401250291712_n.jpg?_nc_cat=110&_nc_oc=AQmr6vh9kVJtmOfm5BG5FdDlF3tothnBjZ4nomXh81sL1frHP8R6cV3x0q1TNlAMh08&_nc_ht=scontent-lht6-1.xx&oh=b6e264af12ad1561bc4a7fa738a80c49&oe=5DC187E3",
    "twitter": "https://twitter.com/clgammon"
  },
  "ronnie-kroell": {
    "name": "Ronnie Kroell",
    "description": "Fashion model, actor, and singer",
    "image": "/images/ronnie-kroell.jpg",
    "imageAttribution": "Ronnie Kroell",
    "party": "Independent",
    "wikipedia": "https://en.wikipedia.org/wiki/Ronnie_Kroell",
    "state": "Illinois",
    "born": "1983-02-01T00:00:00.000Z",
    "short": "Fashion model, actor, and singer",
    "originalImage": "http://ronniekroell.com/wp-content/uploads/2019/07/65673120_354055155280796_4261299811870834688_n-595x595.jpg",
    "twitter": "https://twitter.com/RonnieKroell",
    "website": "http://ronniekroell.com/"
  },
  "mark-charles": {
    "name": "Mark Charles",
    "description": "Activist, consultant, journalist, public speaker",
    "image": "/images/mark-charles.jpg",
    "imageAttribution": "www.markcharles2020.com, Mark Charles",
    "party": "Independent",
    "wikipedia": "https://en.wikipedia.org/wiki/Mark_Charles",
    "state": "Washington",
    "born": "",
    "short": "Activist, consultant, journalist, public speaker",
    "originalImage": "https://images.squarespace-cdn.com/content/v1/5cdb1f398d974027d6a2c408/1559001415193-1FZZ1CVZU29391XX1RZU/ke17ZwdGBToddI8pDm48kB3TaXxmhZvr9a56CCG1MbJ7gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z5QHyNOqBUUEtDDsRWrJLTmihaE5rlzFBImxTetd_yW5RXGqq4O2StArml3Mr-g-OGoDz4S3XykACFaDy9guZk3/MC+Portrait.jpg?format=1500w",
    "twitter": "https://twitter.com/wirelesshogan",
    "website": "https://www.markcharles2020.com/"
  },
  "perry-caravello": {
    "name": "Perry Caravello",
    "description": "Actor and comedian from California",
    "image": "/images/perry-caravello.jpg",
    "imageAttribution": "Perry F\nCaravello",
    "party": "Independent",
    "state": "California",
    "born": "1963-11-17T00:00:00.000Z",
    "wikipedia": "https://en.wikipedia.org/wiki/Perry_Caravello",
    "website": "http://scaryperryproductions.com/",
    "short": "Actor and comedian from California",
    "originalImage": "http://www.scaryperryproductions.com/perry-pic10.jpg",
    "twitter": "https://twitter.com/scaryperryquote"
  },
  "dan-rattiner": {
    "name": "Dan Rattiner",
    "description": "A journalist and newspaper publisher",
    "image": "/images/dan-rattiner.jpg",
    "imageAttribution": "Dan Rattiiner",
    "party": "Independent",
    "state": "New York",
    "born": "1939-08-15T00:00:00.000Z",
    "wikipedia": "https://en.wikipedia.org/wiki/Dan_Rattiner",
    "short": "A journalist and newspaper publisher",
    "originalImage": "https://upload.wikimedia.org/wikipedia/commons/c/c2/Photo_of_Dan_Rattiner.jpg",
    "website": "https://www.danrattiner.org/",
    "twitter": "https://twitter.com/drattiner"
  }
};