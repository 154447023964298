import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';

class Tweets extends React.Component {
  componentDidMount() {
    window.twttr && window.twttr.widgets.load();
  }

  render() {
    const { twitter, name } = this.props; 

    return (
      <Container style={{marginTop: 20}}>
        <a className="twitter-timeline" href={twitter} data-tweet-limit="5">Tweets by {name}</a>
      </Container>
    );
  }
}

Tweets.defaultProps = {
};

Tweets.propTypes = {
  twitter: PropTypes.string,
  name: PropTypes.string,
};

export default Tweets;
