import React from 'react';
import Typography from '@material-ui/core/Typography';
import PageLayout from '../components/PageLayout';
// import ContactUs from '../components/ContactUs';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(8),
  },
}));

const NotFound = () => {
  const classes = useStyles();

  return (
    <PageLayout>
      <div className={classes.container}>
        <Typography variant="h3" align="center" paragraph>
          Oups!
        </Typography>
        <Typography variant="h5" align="center" paragraph>
          The page you are looking could not be found
        </Typography>
      </div>
      {/* <ContactUs /> */}
    </PageLayout>
  );
}

export default NotFound;
