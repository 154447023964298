import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  quote: {
    borderLeft: 'calc(0.2vw + 2px) solid #c1c1c1',
    padding: '0.1% 6% 0.1% 4%',
    margin: '1.6em 1vw',
    fontStyle: 'italic',
  },
});

const Quote = ({ text, variant, cite }) => {
  const classes = useStyles();

  return (
    <blockquote cite={cite} className={classes.quote}>
      <Typography variant={variant}>
        {text}
      </Typography>
    </blockquote>
  );
}

Quote.defaultProps = {
  variant: 'h5',
};

Quote.propTypes = {
  text: PropTypes.string,
  variant: PropTypes.string,
  cite: PropTypes.string,
};

export default Quote;
