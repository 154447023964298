import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  text: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    fontSize: theme.typography.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

function ExternalLink({ link, children }) {
  const classes = useStyles();

  return (
    <a href={link} target="_blank" rel="noopener noreferrer" className={classes.text}>
      {children}
    </a>
  );
}

ExternalLink.propTypes = {
  link: PropTypes.string,
  children: PropTypes.node,
};

export default ExternalLink;