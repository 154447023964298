import React, {useState} from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import PageLayout from '../components/PageLayout';
import ExternalLink from '../components/ExternalLink';
// import ContactUs from '../components/ContactUs';
import CandidateCard from '../components/CandidateCard';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import candidates from '../candidates';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(4),
  },
  heroUnit: {
    backgroundColor: theme.palette.background.paper,
  },
  heroContent: {
    maxWidth: 600,
    margin: '0 auto',
    padding: `${theme.spacing(8)}px 0 ${theme.spacing(6)}px`,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(10),
    padding: theme.spacing(6),
    textAlign: 'center',
  },
  searchBox: {
    marginTop: theme.spacing(4),
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
    margin: '0 auto',
    border: '1px gray solid',
    borderRadius: 4,
  },
  input: {
    marginLeft: 8,
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
}));

const sortedCandidateIds = Object.keys(candidates).sort();
const filterCandidates = (filter) => {
  const filtered = sortedCandidateIds
    .map(id => ({id, ...candidates[id]}))
    .filter(candidate => {
      if (candidate.withdrawn) {
        return false;
      }

      if (!filter) {
        return true;
      }

      return candidate.name.toLowerCase().includes(filter)
        || candidate.state.toLowerCase().includes(filter)
        || candidate.party.toLowerCase().includes(filter)
        || candidate.short.toLowerCase().includes(filter);
    });

  return filtered;
};

const Landing = () => {
  const classes = useStyles();
  const [filtered, setFiltered] = useState(filterCandidates(''));

  return (
    <PageLayout withPadding={false}>
      <div className={classes.heroUnit}>
        <div className={classes.heroContent}>
          <Typography component="h4" variant="h2" align="center" color="textPrimary" gutterBottom>
            2020 United States presidential election
          </Typography>
          <Typography variant="h6" align="center" color="textSecondary" paragraph>
            Candidates (sorted alphabetically)
          </Typography>
          <div className={classes.searchBox}>
            <InputBase
              className={classes.input}
              placeholder="Filter"
              onChange={event => {
                const filter = event.target.value;
                setFiltered(filterCandidates(filter));
              }}
            />
            <IconButton className={classes.iconButton} aria-label="search">
              <SearchIcon />
            </IconButton>
          </div>
        </div>
      </div>

      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <Container>
            <Grid container spacing={3} direction="row" justify="center" alignItems="center">
              {filtered.map(candidate => {
                const {id, name, image, imageAttribution, party, short} = candidate;

                return (
                  <Grid key={id} item xs={12} sm={6} md={4} lg={3}>
                    <CandidateCard name={name} image={image} imageAttribution={imageAttribution} description={short} party={party} link={`/candidate/${id}`} />
                  </Grid>
                );
              })}
            </Grid>
          </Container>
        </Grid>
      </Grid>

      <footer className={classes.footer}>
        <Typography variant="subtitle2" color="secondary">
          <ExternalLink link="https://www.us-elections-2020.com">
            US Elections 2020
          </ExternalLink>
        </Typography>
      </footer>
      {/* <ContactUs /> */}
    </PageLayout>
  );
}

export default Landing;
