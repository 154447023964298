import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
// import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  // grow: {
  //   flexGrow: 1,
  // },
  navBar: {
    zIndex: theme.zIndex.drawer + 1,
    // background: 'transparent',
    // boxShadow: 'none',
  },
  titleLink: {
    textDecoration: 'none',
    color: 'white'
    // color: theme.palette.primary.main,
  },
}));

const NavBar = () => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <AppBar position="fixed" className={classes.navBar}>
        <Toolbar>
          <Link to="/" className={classes.titleLink}>
            <Typography variant="h6" color="inherit" noWrap>
              2020 Elections
            </Typography>
          </Link>
          {/* <div className={classes.grow} />
          <Button color="inherit" component={Link} to="/about">About</Button> */}
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}

NavBar.propTypes = {
  withLoginLogoutButton: PropTypes.bool,
};

export default NavBar;
